<template>
  <div>
    <div class="account_item_head">
      <p class="account_item_head_text text--white">general</p>
    </div>
    <div class="row no-gutters account_item align-items-center">
      <div class="col-12">
        <span
          class="
            text--black text--opaq2 text--small text--bold
            account_item_title
          "
        >
          Created at
        </span>
        <span
          class="text--black text--regular account_item_subtitle overlap"
        >
          {{ userInfo.creationDate | moment }}
        </span>
      </div>

      <div class="row no-gutters account_item align-items-center">
        <div class="col-12">
          <span
            class="
              text--black text--opaq2 text--small text--bold
              account_item_title
            "
          >
            Last modified date
          </span>
          <span
            class="text--black text--regular account_item_subtitle overlap"
          >
            {{ userInfo.lastModifiedDate | moment }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    userInfo: [Array, Object],
  },

  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
}
</script>
